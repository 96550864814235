<template>
  <v-card outlined class="pa-2">
    <div>{{ title }}</div>

    <div class="d-flex">
      <div
        v-for="(item, i) in items"
        :key="`v-${i}`"
        style="padding: 0 1px"
        :style="`flex-basis: ${item.percent}%;`"
      >
        <!-- Item Stat -->
        <div class="text-center text-caption">
          <template v-if="$vuetify.breakpoint.xs ? item.percent > 10 : item.percent > 7">
            {{ item.percent.toFixed(1) }}%
            <span v-if="item.percent > 50"> = {{ item.amount }}฿ </span>
          </template>
          <template v-else>-</template>
        </div>

        <!-- Item Name -->
        <div
          class="
            lighten-4
            text-center text-caption text-sm-body-1
            py-1
          "
          :class="select === item.name ? 'success' : 'light-blue'"
          @click="selectItem(item.name)"
        >
          <strong>
            {{ shortenName(item.name, item.percent, $vuetify.breakpoint.xs) }}
          </strong>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [],
    },
    select: {
      type: String,
    }
  },
  
  methods: {
    shortenName(name, percent, isSmallScreen) {
      if (percent <= 5) {
        return name.slice(0, 1)
      }
  
      if (percent <= 20 || isSmallScreen) {
        return name.slice(0, 3)
      }

      if (name.length > 6) {
        return name.slice(0, 6) + '..'
      }

      return name
    },

    selectItem (name) {
      this.$emit('click', name)
    }
  }
};
</script>

<style>
</style>