
// payers = [{ payer: String, amount: Number }]
const calcRawAmountEach = (renter, payers, n = 1) => {
  let result = {};
  payers.forEach((payer) => {
    if (payer.payer == renter) return;
    let cost = payer.amount / n;
    result[payer.payer] = cost;
  });
  return result;
}

// renters = String[]
export const calcRentersRawCost = (renters, payers) => {
  let rentersRawCost = {};
  renters.forEach((renter) => {
    let sumTr = calcRawAmountEach(renter, payers, renters.length);
    rentersRawCost[renter] = sumTr;
  });
  return rentersRawCost;
}

export const genRenterIsPaid = (rentersRawCost) => {
  let rentersIsPaid = {};
  for (let renter in rentersRawCost) {
    rentersIsPaid[renter] = {};
    for (let payer in rentersRawCost[renter]) {
      rentersIsPaid[renter][payer] = false;
    }
  }
  return rentersIsPaid;
}

export const genRenterPaid = (rentersRawCost) => {
  let rentersPaid = {};
  for (let renter in rentersRawCost) {
    rentersPaid[renter] = {};
    for (let payer in rentersRawCost[renter]) {
      rentersPaid[renter][payer] = 0;
    }
  }
  return rentersPaid;
}

// Summary renters in each transactions that has to pay
export const calcSummarisedRentersRawCostToPay = (transactions) => {
  let rentersRawCost = {};

  // Summary Renters in each transactions
  for (let trId in transactions) {
    let tr = transactions[trId];
    for (let renter in tr.rentersCost) {
      let renterCost = tr.rentersCost[renter];
      let renterPaid = (tr.rentersPaid && tr.rentersPaid[renter]) || {};
      for (let payer in renterCost) {
        // if (tr.rentersIsPaid[renter] && tr.rentersIsPaid[renter][payer])
        // 	continue;

        if (!rentersRawCost[renter]) {
          rentersRawCost[renter] = {};
        }

        if (!(payer in rentersRawCost[renter])) {
          rentersRawCost[renter][payer] = 0
        }

        rentersRawCost[renter][payer] += renterCost[payer] - (renterPaid[payer] || 0);
      }
    }
  }

  return rentersRawCost
}

export const calcSummarisedStat = (transactions) => {
  let rentersStat = {}
  let payersStat = {}
  let totalAmount = 0

  for (const trId in transactions) {
    const tr = transactions[trId]

    // TO DO: Must find another way to computer due to bad data-structure of 'rentersCost'
    // const rentersCost = tr.rentersCost
    // for (const renter in rentersCost) {
    //   const totalAmount = Object.values(rentersCost[renter]).reduce((sum, cur) => sum + cur, 0)

    //   if (!rentersStat[renter]) {
    //     rentersStat[renter] = 0
    //   }

    //   rentersStat[renter] += totalAmount
    // }

    const payers = tr.payers
    for (let i = 0; i < payers.length; i++) {
      const payer = payers[i].payer
      const amount = parseFloat(payers[i].amount) || 0

      if (!payersStat[payer]) {
        payersStat[payer] = { amount: 0 }
      }

      payersStat[payer].amount += amount
      totalAmount += amount
    }
  }

  // Calculate Payers Percentage
  for (let payer in payersStat) {
    payersStat[payer].percent = payersStat[payer].amount * 100 / totalAmount
  }

  return { 
    // renters: rentersStat,
    payers: payersStat,
    totalAmount
  }
}

// rentersRawCost = { [renter]: { [payer]: Number } }
export const calcCostReducerBasic = (rentersRawCost) => {
  let rentersCost = JSON.parse(JSON.stringify(rentersRawCost));

  for (let renter in rentersCost) {
    for (let payer in rentersCost[renter]) {
      if (!rentersCost[renter] || !rentersCost[payer]) continue;

      let renterOwn = rentersCost[renter][payer];
      let payerOwn = rentersCost[payer][renter];
      if (renterOwn && payerOwn) {
        if (renterOwn > payerOwn) {
          rentersCost[renter][payer] = renterOwn - payerOwn;
          delete rentersCost[payer][renter];
        } else if (renterOwn < payerOwn) {
          delete rentersCost[renter][payer];
          rentersCost[payer][renter] = payerOwn - renterOwn;
        } else {
          delete rentersCost[renter][payer];
          delete rentersCost[payer][renter];
        }
      }
    }
  }

  return rentersCost;
}

// rentersRawCost = { renter: { payer: Number } }
export const calcCostReducerByGraph = (rentersRawCost) => {
  let rentersCost = JSON.parse(JSON.stringify(rentersRawCost));

  function findCyclePath(u, isVisited) {
    isVisited[u] = true;
    for (let v in rentersCost[u]) {
      if (isVisited[v]) {
        return {
          // renter -> payer, { renter: payer }
          path: { [u]: v },
          finalEdge: v,
          isFinish: false,
          minCost: rentersCost[u][v]
        };
      } else {
        let res = findCyclePath(v, isVisited);
        if (res.path) {
          if (res.isFinish) return res;
          let cost = rentersCost[u][v];
          if (res.minCost > cost) res.minCost = cost;
          if (u == res.finalEdge) res.isFinish = true;
          return {
            ...res,
            path: { [u]: v, ...res.path }
          }
        }
      }
    }
    isVisited[u] = false;
    return {};
  }

  let reducerLogs = [];
  let isHasCycle = true;
  while (isHasCycle) {
    isHasCycle = false;
    for (let node in rentersCost) {
      let isVisited = {};
      let { path, minCost } = findCyclePath(node, isVisited);

      if (!path) continue;
      isHasCycle = true;
      reducerLogs.push({ path, minCost });

      for (let u in path) {
        let v = path[u];
        // Minus Min Cost
        rentersCost[u][v] -= minCost;

        // Remove Empty Keys
        if (!rentersCost[u][v]) {
          delete rentersCost[u][v];
          if (!Object.keys(rentersCost[u]).length) {
            delete rentersCost[u];
          }
        }
      }
    }
  }

  // console.log(reducerLogs);
  return rentersCost;
}

export const removeNullOrSmallField = (rentersCost) => {
  for (let renter in rentersCost) {
    for (let payer in rentersCost[renter]) {
      if (rentersCost[renter][payer] < 0.1)
        delete rentersCost[renter][payer];
    }
    if (!Object.keys(rentersCost[renter]).length) {
      delete rentersCost[renter];
    }
  }
}

// let rentersRawCost = {
//   A: {
//     B: 100
//   },
//   B: {
//     C: 50,
//     D: 20
//   },
//   C: {
//     A: 70
//   },
//   D: {
//     C: 30
//   }
// }


// console.log(calcCostReducerByGraph(rentersRawCost))
// console.log(calcCostReducerBasic(rentersRawCost));


