import { en } from 'vuetify/lib/locale';

export default {
  gen: {
    notfound: 'Not Found!',
    group: 'Group',
    loading: 'Loading ...',
    language: 'Language',
    nickname: 'Nickname',
    member: 'Member | Members',
    payer: 'Payer | Payers',
    renter: 'Renter | Renters',
    created: 'Created',
    summary: 'Summary',
    using: 'Using',
    for: 'for',
    total: 'total',

    cancel: 'Cancel',
    confirm: 'Confirm',
    add: 'Add',
    delete: 'Delete',
    create: 'Create',

    readmore: 'read more'
  },
  home: {
    go: 'Go',
    groupId: '@:gen.group Id',
    searchHistory: 'Search History'
  },
  group: {
    moneyUsage: 'Money usage',

    addItem: 'Add Item',
    showRawCost: 'Show raw Cost',
    completePayBy: 'All members get paid <b>from {name}</b> !',
    allCompletePayBy: 'All members get paid !',
    userHasToPay: '<b>{name}</b> has to pay {amount} ฿ to',
    itemName: 'Item name',
    cost: 'Cost',
    amount: 'Amount',

    // Dialog
    deleteThisGroup: 'Delete this group ?',
    userPayToUser: "{user1} pay to '{user2}' ?",
    userUnPayToUser: "{user1} UnPay to '{user2}' ?",
    removeThisItem: 'Remove this item ?'
  },
  createGroup: {
    groupName: 'Group name',
    useGraphTopo: [
      'Use graph topology (Beta)',
      'For reducing summary money'
    ],
    yourGroupId: 'Your Group Id is',
    shareThis: 'Share this'
  },
  ...en,
}