import { th } from 'vuetify/lib/locale';

export default {
  gen: {
    notfound: 'ไม่พบ!',
    group: 'กลุ่ม',
    loading: 'กำลังโหลด ...',
    language: 'ภาษา',
    nickname: 'ชื่อเล่น',
    member: 'สมาชิก',
    payer: 'ผู้จ่าย',
    renter: 'ผู้ยืม',
    created: 'สร้างเมื่อ',
    summary: 'สรุป',
    using: 'ใช้',
    for: 'สำหรับ',
    total: 'ทั้งหมด',

    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    add: 'เพิ่ม',
    delete: 'ลบ',
    create: 'สร้าง',

    readmore: 'อ่านเพิ่ม'
  },
  home: {
    go: 'ค้นหา',
    groupId: 'ไอดี@:gen.group',
    searchHistory: 'ประวัติการค้นหา'
  },
  group: {
    moneyUsage: 'การใช้เงิน',

    addItem: 'เพิ่มรายการ',
    showRawCost: 'แสดงเงินก่อนคำนวน',
    completePayBy: '<b>{name}</b> ได้คืนเงินให้ทุกคนแล้ว !',
    allCompletePayBy: 'ทุกคนคืนเงินครบแล้ว !',
    userHasToPay: '<b>{name}</b> ต้องจ่าย {amount} ฿ ให้',
    itemName: 'ชื่อรายการ',
    cost: 'ราคา',
    amount: 'จำนวน',

    // Dialog
    deleteThisGroup: 'ลบกลุ่มนี้ ?',
    userPayToUser: "{user1} จ่ายให้กับ '{user2}' ?",
    userUnPayToUser: "{user1} ยกเลิกการจ่าย '{user2}' ?",
    removeThisItem: 'ลบรายการนี้ ?'
  },
  createGroup: {
    groupName: 'ชื่อกลุ่ม',
    useGraphTopo: [
      'ใช้ Graph Topology (ทดสอบ)',
      'สำหรับคำนวนเงินสรุป'
    ],
    yourGroupId: 'ไอดีกลุ่มของคุณคือ',
    shareThis: 'แชร์'
  },
  ...th,
}