import { initializeApp } from "firebase/app"

const firebaseConfig = {
	apiKey: "AIzaSyBnOr1PObixZFNehRK4HQjr3ZEq4n8hA_E",
	authDomain: "viemonie-th.firebaseapp.com",
	projectId: "viemonie-th",
	storageBucket: "viemonie-th.appspot.com",
	messagingSenderId: "495016890256",
	appId: "1:495016890256:web:4a51385eaf8ba69f8cad15",
};

export default initializeApp(firebaseConfig);