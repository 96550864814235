import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import CreateGroup from "../views/CreateGroup.vue";
import Storage from "../views/Storage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  }, {
    path: "/create",
    name: "CreateGroup",
    component: CreateGroup,
  }, {
    path: "/storage",
    name: "Storage",
    component: Storage,
  }, {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { y: 60 } }
    }
  },
});

export default router;
