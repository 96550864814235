<template>
  <v-card color="pa-5">
    <!-- Item info -->
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="title"
          :label="$t('group.itemName')"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model="amount"
          :label="$t('group.cost')"
          suffix="THB"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Payers List -->
    <v-row class="align-center" v-for="(_, i) in payers" :key="i">
      <!-- Member Select -->
      <v-col>
        <v-select
          hide-details
          :items="members"
          :label="$tc('gen.payer')"
          v-model="payers[i].payer"
          disabled
        ></v-select>
      </v-col>

      <!-- Pay Amount -->
      <v-col>
        <v-text-field
          hide-details
          type="number"
          :label="$t('group.amount')"
          suffix="THB"
          v-model="payers[i].amount"
        ></v-text-field>
      </v-col>

      <v-col class="flex-grow-0 flex-shrink-1">
        <v-btn small icon @click="delPayer(i)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- New Payer Field -->
    <v-row v-if="amountLeft > 0">
      <!-- Member Select -->
      <v-col>
        <v-select
          hide-details
          :items="selectableMembers"
          :label="$tc('gen.payer')"
          v-model="payer.payer"
        ></v-select>
      </v-col>

      <!-- Pay Amount -->
      <v-col>
        <v-text-field
          hide-details
          type="number"
          :label="`${$t('group.amount')} (${amountLeft} THB)`"
          suffix="THB"
          v-model="payer.amount"
          @keydown.enter="addPayer()"
        ></v-text-field>
      </v-col>

      <v-col class="flex-grow-0 flex-shrink-1">
        <v-btn class="mt-5" small icon color="info" @click="addPayer()"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <!-- Choose Renters -->
    <v-select
      class="mt-5"
      :label="$tc('gen.renter', renters.length)"
      v-model="renters"
      :items="members"
      outlined
      small-chips
      multiple
      hide-details
    ></v-select>

    <div class="text-right mt-5">
      <v-btn color="mr-3" @click="$emit('cancel')">{{
        $t("gen.cancel")
      }}</v-btn>
      <v-btn color="success" @click="addTransaction()" :disabled="!isAddAble">{{
        $t("gen.add")
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import Group from "@/service/group.js";

export default {
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Group,
      default: () => ({}),
    },
  },

  data: () => ({
    title: "",
    amount: null,
    payer: { payer: "", amount: null },
    payers: [],
    renters: [],
  }),

  created() {
    this.renters = this.members;
  },

  methods: {
    addPayer() {
      if (!this.payer.payer) return;
      if (!this.payer.amount) this.payer.amount = this.amountLeft;
      this.payer.amount = parseFloat(this.payer.amount);
      this.payers.push(this.payer);
      this.payer = { payer: "", amount: null };
    },

    delPayer(i) {
      this.payers.splice(i, 1);
    },

    async addTransaction() {
      if (!this.isAddAble) return;

      this.appIsLoading();
      let isSuccess = await this.group.addTransaction({
        title: this.title,
        amount: this.amount,
        payers: this.payers,
        renters: this.renters,
      });
      this.appIsLoaded();
      if (!isSuccess) {
        return console.error("Can't add a transaction!");
      }

      this.title = "";
      this.amount = null;
      this.payers = [];
      this.$emit("done");
    },

    ...mapActions(["appIsLoading", "appIsLoaded"]),
  },

  computed: {
    selectableMembers() {
      let payers = this.payers.map((e) => e.payer);
      let selectable = this.members.filter((e) => !payers.includes(e));
      return selectable || [];
    },

    amountLeft() {
      let sum = this.payers.reduce((s, e) => s + parseFloat(e.amount), 0);
      return this.amount - sum;
    },

    isAddAble() {
      return (
        this.title &&
        this.amount &&
        this.payers.length &&
        this.renters.length &&
        this.amountLeft === 0
      );
    },
  },

  watch: {
    members(v) {
      this.renters = v;
    },
  },
};
</script>