import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appIsLoading: false,
    appDialog: {
      callback: null,
      isInUse: false,
      title: "",
      body: "",
      color: ""
    }
  },
  getters: {
    appIsLoading(state) {
      return state.appIsLoading;
    },

    appDialogCallback(state) {
      return state.appDialog.callback;
    },
    appDialogIsInUse(state) {
      return state.appDialog.isInUse;
    },
    appDialogBody(state) {
      let { title, body, color } = state.appDialog;
      return { title, body, color };
    }
  },
  mutations: {
    SET_APP_IS_LOADING(state, value) {
      state.appIsLoading = value;
    },

    SET_APP_DIALOG_CALLBACK(state, func) {
      state.appDialog.callback = func;
    },
    SET_APP_DIALOG_BODY(state, { title, body, color }) {
      state.appDialog.title = title;
      state.appDialog.body = body;
      state.appDialog.color = color;
    },
    SET_APP_DIALOG_ISINUSE(state, value) {
      state.appDialog.isInUse = value;
    }
  },
  actions: {
    appIsLoading({ commit }) {
      commit('SET_APP_IS_LOADING', true);
    },
    appIsLoaded({ commit }) {
      commit('SET_APP_IS_LOADING', false);
    },

    async appOpenDialog({ commit, getters }, { title, body, color } = {}) {
      if (getters.appDialogIsInUse) {
        getters.appDialogCallback(false);
        throw new Error('Dialog is in use');
      }
      
      commit('SET_APP_DIALOG_ISINUSE', true);
      commit('SET_APP_DIALOG_BODY', { title, body, color });
      let result = await new Promise((res) => {
        commit('SET_APP_DIALOG_CALLBACK', res);
      });
      commit('SET_APP_DIALOG_CALLBACK', null);
      commit('SET_APP_DIALOG_ISINUSE', false);
      return result;
    }
  },
});
