<template>
  <!-- Loading Box -->
  <v-snackbar v-model="appIsLoading" :timeout="-1">
    {{ $t('gen.loading') }}
    <template v-slot:action="{ attrs }">
      <v-btn loading small text v-bind="attrs" disabled></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: mapGetters(['appIsLoading'])
};
</script>