import firebase from "./firebase"
import { getDownloadURL, getStorage, list, ref, uploadBytes } from "firebase/storage";
import md5 from "md5";

const storage = getStorage();

// =====================================================
//                    S T O R A G E
// =====================================================

export const uploadFile = async (file, folder="images") => {
  let buffer = await file.text();
  let fileName = md5(buffer);

  const storageRef = ref(storage, `${folder}/${fileName}`);
  let fileUrl = await getLink(fileName, folder);
  if (fileUrl) return { fileName, fileUrl }

  await uploadBytes(storageRef, file);
  return { fileName };
}

export const deleteFile = async (fileName, folder="images") => {
  try {
    const storageRef = ref(storage, `${folder}/${hash}`);
    await deleteObject(storageRef);
    return true;
  } catch (e) {
    return false;
  }
}

export const listFile = async (folder="images") => {
  const storageRef = ref(storage, `${folder}`);
  return await list(storageRef);
}

export const getLink = async (fileName, folder="images") => {
  if (!fileName) return; 
  const storageRef = ref(storage, `${folder}/${fileName}`);
  try {
    return await getDownloadURL(storageRef);
  } catch (e) {
    return false;
  }
}

async function main() {
}

main();
