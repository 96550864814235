import {
	addMembers,
	addTransaction,
	createGroup,
	delGroup,
	deleteTransaction,
	getGroup,
	getTransactions,
	setPayment
} from "./firestore.js";

import {
	calcCostReducerBasic,
	calcCostReducerByGraph,
	calcRentersRawCost,
	genRenterIsPaid,
	genRenterPaid,
	removeNullOrSmallField,
	calcSummarisedRentersRawCostToPay,
	calcSummarisedStat
} from "./calculator.js";

class Group {
	constructor(groupId) {
		this.groupId = groupId;
		this.name = "";
		this.members = [];
		this.timestamp = null;
		this.isUsingGraph = false;

		this.transactions = {};
		this.rentersCost = {};
		this.rentersRawCost = {};
		this.stat = {}
	}

	async create(name, members = [], isUsingGraph = false) {
		if (!name) throw new Error("Group needs its name");
		this.name = name;
		this.members = members;
		this.isUsingGraph = isUsingGraph;
		this.groupId = await createGroup(name, members, isUsingGraph);
		return this.groupId;
	}

	async delete() {
		if (Object.keys(this.transactions).length) return false;
		await delGroup(this.groupId);
		this.groupId = "";
		this.name = "";
	}

	async getInfo() {
		let group = await getGroup(this.groupId);
		this.name = group.name || "";
		this.members = group.members || [];
		this.isUsingGraph = group.isUsingGraph;
		this.timestamp = group.timestamp;
		return group;
	}

	async addMembers(members = []) {
		this.members = await addMembers(this.groupId, members);
	}

	// payers = [{ [payer]: amount }], renters = String[]
	async addTransaction({ title, amount, payers, renters }) {
		let rentersRawCost = calcRentersRawCost(renters, payers);
		let rentersCost = calcCostReducerBasic(rentersRawCost);
		// let rentersIsPaid = genRenterIsPaid(rentersCost);
		let rentersPaid = genRenterPaid(rentersCost);
		let transaction = {
			title: title,
			amount: amount,
			payers: payers,
			rentersCost,
			// rentersIsPaid,
			rentersPaid,
		};
		let res = await addTransaction(this.groupId, transaction);
		if (!res) return false;
		this.transactions = { [res.trId]: res.transaction, ...this.transactions };
		return res;
	}

	async deleteTransaction(trId) {
		await deleteTransaction(this.groupId, trId);
		delete this.transactions[trId];
		return true;
	}

	async getTransactions() {
		let transactions = await getTransactions(this.groupId);
		this.transactions = transactions;
		return transactions;
	}

	async setPayment(trId, renter, payer, amount, isAdd) {
		return await setPayment(this.groupId, trId, renter, payer, amount, isAdd);
	}

	async setPaymentPair(renter, payer, amount = this.rentersCost[renter][payer]) {
		if (this.isUsingGraph) {
			// Using Graph
			for (let trId in this.transactions) {
				let tr = this.transactions[trId];
				if (!tr.rentersCost[renter] || !tr.rentersCost[renter][payer]) continue;
				let cost = tr.rentersCost[renter][payer];
				if (amount >= cost) {
					await this.setPayment(trId, renter, payer, cost);
				} else if (amount > 0) {
					await this.setPayment(trId, renter, payer, amount);
					break;
				}
				amount -= cost;
			}
		} else {
			// Using Basic Calc
			for (let trId in this.transactions) {
				let tr = this.transactions[trId];
				if (tr.rentersCost[renter] && tr.rentersCost[renter][payer]) {
					await this.setPayment(
						trId,
						renter,
						payer,
						tr.rentersCost[renter][payer]
					);
				} else if (tr.rentersCost[payer] && tr.rentersCost[payer][renter]) {
					await this.setPayment(
						trId,
						payer,
						renter,
						tr.rentersCost[payer][renter]
					);
				}
			}
		}
		return true;
	}

	async deleteAllTransactions() {
		for (let trId in this.transactions) {
			await this.deleteTransaction(trId);
		}
		return true;
	}

	// ===================================================
	//                  Helper Function
	// ===================================================
	sortMembers() {
		this.members.sort((a, b) => a.localeCompare(b));
	}

	sortRentersAndPayersEachTransaction() {
		for (let trId in this.transactions) {
			let tr = this.transactions[trId];
			// Sort Renters (rentersPaid)
			tr.rentersPaid = Object.keys(tr.rentersPaid)
				.sort()
				.reduce((obj, key) => {
					obj[key] = tr.rentersPaid[key];
					return obj;
				}, {});
			// Sort Payer
			tr.payers.sort((a, b) => a.payer.localeCompare(b.payer));
		}
	}

	computeRentersRawCostEachTr() {
		for (let trId in this.transactions) {
			let tr = this.transactions[trId];
			tr.rentersRawCost = calcRentersRawCost(
				Object.keys(tr.rentersPaid),
				tr.payers
			);
		}
	}

	computeSummary() {
		// Summary renters in each transactions that has to pay
		let rentersRawCost = calcSummarisedRentersRawCostToPay(this.transactions)
		this.rentersRawCost = rentersRawCost;

		// Calculate Net Cost
		let rentersCost = this.isUsingGraph
			? calcCostReducerByGraph(rentersRawCost)
			: calcCostReducerBasic(rentersRawCost);

		// Remove null field or too small value
		removeNullOrSmallField(rentersCost);
		this.rentersCost = rentersCost;

		this.stat = calcSummarisedStat(this.transactions)
	}
}

async function main() {
	let myGroup = new Group();
	await myGroup.create('hat yai', ['a', 'b']);
	console.log(myGroup);
	await myGroup.addTransaction({
		title: 'foods',
		amount: 20,
		payers: [{ payer: "a", amount: 20 }],
		renters: ["b"]
	});

	await myGroup.addTransaction({
		title: 'cake',
		amount: 120,
		payers: [{ payer: "a", amount: 120 }],
		renters: ["a", "b"]
	});
	console.log(myGroup);


	// ex 2
	// let group = new Group('yarAuAYUUE0jHRmTcwHR');
	// await group.getInfo();
	// group.sortMembers();

	// await group.getTransactions();
	// group.sortRentersAndPayersEachTransaction();
	// group.computeRentersRawCostEachTr();
	// group.computeSummary();
	// console.log(group);

	// await group.deleteAllTransactions();
}

// main();

export default Group;