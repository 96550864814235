var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isUserInTransaction)?_c('v-card',{staticClass:"pa-5"},[_c('v-card-title',{staticClass:"pa-0 align-baseline"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.tr.title))]),(_vm.isShowRawCost)?_c('span',{staticClass:"caption ml-1"},[_vm._v("#"+_vm._s(_vm.trId))]):_vm._e(),_c('v-spacer'),_c('span',{staticClass:"ml-auto d-flex align-baseline"},[_c('span',[_vm._v(_vm._s(Number(_vm.tr.amount).toLocaleString())+" THB")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.delTransactionAndReloadTr(_vm.trId, _vm.tr.title)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"caption float-right"},[_vm._v(" "+_vm._s(_vm.$d(_vm.$options.filters.todate(_vm.tr.timestamp), "med"))+" ")]),_c('div',{staticClass:"subtitle-2 mt-3"},[_vm._v(_vm._s(_vm.$tc("gen.payer", _vm.tr.payers.length)))]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.tr.payers),function(payer,i){return _c('v-card',{key:i,staticClass:"py-1 px-2 ma-1",class:{
        'success lighten-4': _vm.user == payer.payer,
      },attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(payer.payer)+" +"+_vm._s(payer.amount)+" THB ")])])}),1),_c('div',{staticClass:"subtitle-2 mt-4"},[_vm._v(" "+_vm._s(_vm.$tc("gen.renter", Object.keys(_vm.tr.rentersPaid).length))+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.tr.rentersPaid),function(renterPaid,renter){return _c('v-card',{key:renter,staticClass:"px-2 py-1 ma-1 renter-box lighten-4",class:{
        red: _vm.user == renter && !_vm.isCompletePaid(_vm.tr, renter),
        success: _vm.user == renter && _vm.isCompletePaid(_vm.tr, renter),
      },attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(renter))]),_c('v-divider',{staticClass:"my-1"}),_vm._l((_vm.dynRentersCost[renter]),function(amount,payer){return _c('v-btn',{key:payer,staticClass:"caption mt-1",class:{
          'text-decoration-line-through':
            !_vm.isShowRawCost && _vm.isRenterPaid(_vm.tr, renter, payer),
        },attrs:{"x-small":"","block":"","depressed":"","disabled":_vm.isDisablePayFor(renter, payer)},on:{"click":function($event){return _vm.setPaid(_vm.tr, renter, payer)}}},[_vm._v(" "+_vm._s(payer)+" "+_vm._s(amount.toFixed(2))+" "),(
            _vm.tr.rentersPaid &&
            _vm.tr.rentersPaid[renter][payer] &&
            !_vm.isRenterPaid(_vm.tr, renter, payer)
          )?_c('b',{staticClass:"success--text"},[_vm._v(" - "+_vm._s(_vm.tr.rentersPaid[renter][payer].toFixed(2))+" ")]):_vm._e(),_vm._v(" THB ")])}),(!Object.keys(_vm.dynRentersCost[renter]).length)?_c('div',[_vm._v("-")]):_vm._e()],2)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }