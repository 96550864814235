<template>
  <v-app>
    <NavBar />

    <v-main class="mt-4">
      <router-view class="pb-4"/>
    </v-main>

    <LoadingOverlay/>
    <DialogOverlay/>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import LoadingOverlay from './components/LoadingOverlay.vue';
import DialogOverlay from './components/DialogOverlay.vue';

export default {
  name: "App",

  components: { NavBar, LoadingOverlay, DialogOverlay },

  data: () => ({
  }),
};
</script>

<style>
@import './App.css';
</style>