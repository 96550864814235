import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en";
import th from "@/locales/th";
import dateTimeFormats from "@/locales/dateTimeFormats";

Vue.use(VueI18n);

const messages = { en, th };

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  dateTimeFormats,
});