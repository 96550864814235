<template>
  <v-container>
    <v-row class="align-center" no-gutters>
      <v-file-input
        label="File input"
        outlined
        dense
        hide-details
        v-model="file"
      ></v-file-input>
      <v-btn text class="ml-3" @click="uploadFile()">Upload</v-btn>
    </v-row>

    <div v-if="fileUrl" class="mt-5">
      <a :href="fileUrl">{{ fileUrl }}</a>
      <v-btn small text @click="deleteFile()">Delete File</v-btn>
    </div>

    <div class="mt-2" v-for="(item, i) in fileItems" :key="i">
      {{ i + 1 }}. {{ item._location.path_ }}
    </div>
  </v-container>
</template>

<script>
import {
  uploadFile,
  getLink,
  deleteFile,
  listFile,
} from "@/service/storage.js";

export default {
  data: () => ({
    file: null,
    fileUrl: "",
    fileName: "",

    fileItems: [],
  }),

  mounted() {
    // listFile().then((res) => (this.fileItems = res.items));
  },

  methods: {
    // async uploadFile() {
    //   let res = await uploadFile(this.file);
    //   this.fileName = res.fileName;
		// 	this.fileUrl = await getLink(this.fileName);
    // },

    // async deleteFile() {
    //   console.log(await deleteFile(this.fileName));
    // },
  },
};
</script>

<style>
</style>